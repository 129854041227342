.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    background-color: white;

    .form {
      display: flex;
      flex-flow: column;
      justify-content: center;

      margin-bottom: 15px;

      .field:nth-of-type(1) {
        margin: -3px 0px 0px;
      }
      .field:nth-of-type(2) {
        margin: 15px 0px;
      }
    }
  }
}
