.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    .input {
      margin-bottom: 15px;
    }
  }
}
