.card {
  width: calc(85% - 30px);
  height: calc(85% - 30px);

  max-width: 850px;

  .header {
    font-size: 50px;
  }

  .content {
    display: flex;
    flex-flow: column;

    height: 100%;
    overflow: hidden;

    .tasks {
      overflow-y: auto;
      scrollbar-color: rgb(155, 155, 155) white;
      scrollbar-width: thin;
    }

    .actions {
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-start;
      align-items: flex-end;
      flex: 1 0 0;

      width: 100%;
      margin-top: 15px;
    }
  }
}
