.wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: 5px;

  .check {
    margin-right: 5px;
  }

  .task {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;

    .time {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      font-size: 0.9em;
      .string {
        margin-left: 5px;
      }
    }
  }

  .fill {
    flex: 1 0 0;
  }

  .more {
    margin-right: 5px;
  }
}

.border {
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 5px;
}

.border:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}
