.container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-bottom: 15px;

  .dropdown {
    width: 100%;
  }
}
