html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: linear-gradient(#0b0b32, #272727, #0b0b32);

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;

  overflow: hidden;
}

.card {
  display: flex;
  flex-flow: column;

  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

  padding: 15px;
}
